import { default as adminerWYzR74iGMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/admin.vue?macro=true";
import { default as dataVXgaYVybE8Meta } from "/Users/jedesh/Work/Repository/console-v2/pages/data.vue?macro=true";
import { default as faqX3aFbWhUFhMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/guides/faq.vue?macro=true";
import { default as indexB4eohIcURXMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/guides/index.vue?macro=true";
import { default as indexy2ltCmTIc2Meta } from "/Users/jedesh/Work/Repository/console-v2/pages/index.vue?macro=true";
import { default as maintenanceuuejSoMGd3Meta } from "/Users/jedesh/Work/Repository/console-v2/pages/maintenance.vue?macro=true";
import { default as apr_452023FDeV4DELMFMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/release-notes/apr-2023.vue?macro=true";
import { default as aug_452023by30jn0bTmMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/release-notes/aug-2023.vue?macro=true";
import { default as aug_452024aaM1ovInUKMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/release-notes/aug-2024.vue?macro=true";
import { default as dec_452023KrjNt9o3hlMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/release-notes/dec-2023.vue?macro=true";
import { default as feb_452023NOPPWfATC1Meta } from "/Users/jedesh/Work/Repository/console-v2/pages/release-notes/feb-2023.vue?macro=true";
import { default as index2d7ltt9Gv2Meta } from "/Users/jedesh/Work/Repository/console-v2/pages/release-notes/index.vue?macro=true";
import { default as jan_4520243orKySuwzVMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/release-notes/jan-2024.vue?macro=true";
import { default as jul_452023lDqM0ZUdniMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/release-notes/jul-2023.vue?macro=true";
import { default as jul_452024UJ3lcSYHgdMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/release-notes/jul-2024.vue?macro=true";
import { default as jun_452023QzntjahsLUMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/release-notes/jun-2023.vue?macro=true";
import { default as jun_452024hpKNugXjbZMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/release-notes/jun-2024.vue?macro=true";
import { default as mar_452023CLzKNsw27uMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/release-notes/mar-2023.vue?macro=true";
import { default as mar_452024ftwr69ElZwMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/release-notes/mar-2024.vue?macro=true";
import { default as may_452023fRzkurp6qFMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/release-notes/may-2023.vue?macro=true";
import { default as may_452024R6YRxjKIdRMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/release-notes/may-2024.vue?macro=true";
import { default as nov_452023aseZ0RWjBoMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/release-notes/nov-2023.vue?macro=true";
import { default as nov_452024DboQjVRvJ3Meta } from "/Users/jedesh/Work/Repository/console-v2/pages/release-notes/nov-2024.vue?macro=true";
import { default as oct_4520236gdUc3aW3CMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/release-notes/oct-2023.vue?macro=true";
import { default as oct_452024rngnrx388YMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/release-notes/oct-2024.vue?macro=true";
import { default as sep_452023EHpHIc0h3nMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/release-notes/sep-2023.vue?macro=true";
import { default as commercial_45analyticsdrP191ITbUMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/sample-dashboards/commercial-analytics.vue?macro=true";
import { default as indexoFIUGAc32dMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/sample-dashboards/index.vue?macro=true";
import { default as mdm_45customer_45masterWY9t8P46TcMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/sample-dashboards/mdm-customer-master.vue?macro=true";
import { default as sales_45opsA5D1E0vZHbMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/sample-dashboards/sales-ops.vue?macro=true";
import { default as indexbNNsXvLpJEMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/schema/index.vue?macro=true";
import { default as indexKhxPynFVDCMeta } from "/Users/jedesh/Work/Repository/console-v2/pages/viz/index.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/admin.vue")
  },
  {
    name: "data",
    path: "/data",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/data.vue")
  },
  {
    name: "guides-faq",
    path: "/guides/faq",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/guides/faq.vue")
  },
  {
    name: "guides",
    path: "/guides",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/guides/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceuuejSoMGd3Meta || {},
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/maintenance.vue")
  },
  {
    name: "release-notes-apr-2023",
    path: "/release-notes/apr-2023",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/release-notes/apr-2023.vue")
  },
  {
    name: "release-notes-aug-2023",
    path: "/release-notes/aug-2023",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/release-notes/aug-2023.vue")
  },
  {
    name: "release-notes-aug-2024",
    path: "/release-notes/aug-2024",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/release-notes/aug-2024.vue")
  },
  {
    name: "release-notes-dec-2023",
    path: "/release-notes/dec-2023",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/release-notes/dec-2023.vue")
  },
  {
    name: "release-notes-feb-2023",
    path: "/release-notes/feb-2023",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/release-notes/feb-2023.vue")
  },
  {
    name: "release-notes",
    path: "/release-notes",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/release-notes/index.vue")
  },
  {
    name: "release-notes-jan-2024",
    path: "/release-notes/jan-2024",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/release-notes/jan-2024.vue")
  },
  {
    name: "release-notes-jul-2023",
    path: "/release-notes/jul-2023",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/release-notes/jul-2023.vue")
  },
  {
    name: "release-notes-jul-2024",
    path: "/release-notes/jul-2024",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/release-notes/jul-2024.vue")
  },
  {
    name: "release-notes-jun-2023",
    path: "/release-notes/jun-2023",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/release-notes/jun-2023.vue")
  },
  {
    name: "release-notes-jun-2024",
    path: "/release-notes/jun-2024",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/release-notes/jun-2024.vue")
  },
  {
    name: "release-notes-mar-2023",
    path: "/release-notes/mar-2023",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/release-notes/mar-2023.vue")
  },
  {
    name: "release-notes-mar-2024",
    path: "/release-notes/mar-2024",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/release-notes/mar-2024.vue")
  },
  {
    name: "release-notes-may-2023",
    path: "/release-notes/may-2023",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/release-notes/may-2023.vue")
  },
  {
    name: "release-notes-may-2024",
    path: "/release-notes/may-2024",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/release-notes/may-2024.vue")
  },
  {
    name: "release-notes-nov-2023",
    path: "/release-notes/nov-2023",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/release-notes/nov-2023.vue")
  },
  {
    name: "release-notes-nov-2024",
    path: "/release-notes/nov-2024",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/release-notes/nov-2024.vue")
  },
  {
    name: "release-notes-oct-2023",
    path: "/release-notes/oct-2023",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/release-notes/oct-2023.vue")
  },
  {
    name: "release-notes-oct-2024",
    path: "/release-notes/oct-2024",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/release-notes/oct-2024.vue")
  },
  {
    name: "release-notes-sep-2023",
    path: "/release-notes/sep-2023",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/release-notes/sep-2023.vue")
  },
  {
    name: "sample-dashboards-commercial-analytics",
    path: "/sample-dashboards/commercial-analytics",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/sample-dashboards/commercial-analytics.vue")
  },
  {
    name: "sample-dashboards",
    path: "/sample-dashboards",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/sample-dashboards/index.vue")
  },
  {
    name: "sample-dashboards-mdm-customer-master",
    path: "/sample-dashboards/mdm-customer-master",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/sample-dashboards/mdm-customer-master.vue")
  },
  {
    name: "sample-dashboards-sales-ops",
    path: "/sample-dashboards/sales-ops",
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/sample-dashboards/sales-ops.vue")
  },
  {
    name: "schema",
    path: "/schema",
    meta: indexbNNsXvLpJEMeta || {},
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/schema/index.vue")
  },
  {
    name: "viz",
    path: "/viz",
    meta: indexKhxPynFVDCMeta || {},
    component: () => import("/Users/jedesh/Work/Repository/console-v2/pages/viz/index.vue")
  }
]