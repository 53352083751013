import { defineStore } from 'pinia'
import * as Sentry from '@sentry/vue'
import type {
  IBreadCrumb,
  IEntitledProduct,
  INotification,
  INotificationStatus,
  IProduct,
  IProducts,
  IRouteMap,
  ISampleDashboardCategory,
  IUser,
  IRouteSubCategory,
} from './types/app'
import AppService from '~/services/app-service'

export const useAppStore = defineStore('appStore', () => {
  const appService = new AppService()
  // State
  const user: Ref<IUser> = ref({} as IUser)
  const isLoading: Ref<number> = ref(0)
  const products: Ref<IProducts> = ref({} as IProducts)
  const notifications: Ref<INotification[]> = ref([] as INotification[])
  const breadcrumbs: Ref<IBreadCrumb[]> = ref([])
  const rawProductsData: Ref<IEntitledProduct[]> = ref([])
  const allowNoAuthUrlList = ['viz', 'schema/?s', 'schema?s']
  const userLoaded: Ref<boolean> = ref(false)
  const embedPublicUser: Ref<boolean> = ref(false)
  const isRenewBannerDismissed: Ref<boolean> = ref(false)
  const isExpiryBannerVisible: Ref<boolean> = ref(false)
  const routeMap: Ref<IRouteMap> = ref({
    'Sample Dashboards': '/sample-dashboards',
    Data: '/data',
    Guides: '/guides',
    'Release Notes': '/release-notes',
    'Admin Apps': '/admin',
  })
  const routeSubCategory: Ref<IRouteSubCategory> = ref({
    'Commercial analytics': 'commercial-analytics',
    'Sales operations': 'sales-ops',
    'MDM/Customer master': 'mdm-customer-master',
  })

  const oldVersion: Ref<string> = ref('')
  const newVersion: Ref<string> = ref('')
  const newVersionAvailable: Ref<boolean> = ref(false)

  const isUserIdSet: Ref<boolean> = ref(false)
  const isUnderMaintenance: Ref<boolean> = ref(false)

  // Mutations
  const setUser = (data: IUser) => {
    user.value = data

    // Sentry user context
    Sentry.setUser({ email: data.email })
    Sentry.setContext('userinfo', {
      hashid: data.hashid,
    })
  }

  const setNotifications = (data: INotification[]) => {
    notifications.value = data
  }

  const isNoAuthAllowed = () => {
    const noAuthAllowed = allowNoAuthUrlList.some((word) =>
      window.location.href.includes(word),
    )
    return noAuthAllowed
  }

  const hasUserInfo = (): boolean => {
    return !!(user.value && user.value.name)
  }

  const updateIsLoading = (value: boolean) => {
    isLoading.value = Math.max(isLoading.value + (value ? 1 : -1), 0)
  }

  const setProducts = (data: IEntitledProduct[]) => {
    products.value = getProducts(data)
  }

  const setRawProducts = (payload: IEntitledProduct[]) => {
    rawProductsData.value = payload
  }

  const setUserLoaded = (payload: boolean) => {
    userLoaded.value = payload
  }

  const setEmbedPublicUser = (payload: boolean) => {
    embedPublicUser.value = payload
  }

  const setRenewDismissed = (payload: boolean) => {
    isRenewBannerDismissed.value = payload
    isExpiryBannerVisible.value = !payload
  }

  const setIExpiryBannerVisible = (payload: boolean) => {
    isExpiryBannerVisible.value = payload
  }

  const updateIsUserIdSet = (payload: boolean) => {
    isUserIdSet.value = payload
  }

  const updateNotificationStatusAsRead = (notificationIds: any) => {
    // Updates/mark the notification center's notification status to read on user interaction.
    // User interaction can be a notification item click or the card click
    notifications.value.forEach((notification) => {
      if (notificationIds.includes(notification.id)) {
        notification.read = true
      }
    })

    user.value.notifications.forEach((notification) => {
      if (notificationIds.includes(notification.id)) {
        notification.read = true
      }
    })
  }

  const updateProductNotifStatus = (
    product: IProduct,
    notificationId: string = '',
  ) => {
    // The banners on the product cards are shown based on the notif_status of a product.
    // The same should be updated to 'state.products' to refresh the card's status
    // This refresh is handled in particular for the updates banner click as
    // there will be no route change and api call to refresh the data.
    if (product.category === 'Sample Dashboards') {
      // eslint-disable-next-line dot-notation
      products.value['SampleDashboards'].forEach((item: any) => {
        item.data.forEach((item: any) => {
          if (item.slug === product.slug) {
            item.notif_status = null
          }
        })
      })
    } else {
      products.value[product.category.replace(' ', '')].forEach((item: any) => {
        if (item.slug === product.slug) {
          if (notificationId) {
            item.notif_status.count = item.notif_status.count - 1
            if (item.notif_status.count > 0) {
              const notificationIndex =
                item.notif_status.notifications.findIndex(
                  (item: string) => item === notificationId,
                )
              item.notif_status.notifications =
                item.notif_status.notifications.splice(notificationIndex, 1)
            } else {
              item.notif_status = null
            }
          } else {
            item.notif_status = null
          }
        }
      })
    }
  }

  const setBreadcrumbs = (payload: IBreadCrumb | null) => {
    breadcrumbs.value = []
    // Payload can be sent null to reset the breadcrumbs
    if (payload) {
      const product = user.value.entitled_products.find((product) =>
        // Child routes will hold the parent route path --> currentRoute.path
        // Find the parent item using the path in currentRoute
        product.primary_url.includes(payload.path),
      )
      if (!product) return
      const parent = {
        name: product.category,
        path: routeMap.value[product.category],
        isActive: false,
      }
      if (product.sub_category) {
        parent.path += `/${routeSubCategory.value[product.sub_category]}`
      }
      breadcrumbs.value.push(parent)

      breadcrumbs.value.push(payload)
    }
  }

  const setVersion = (value: string) => {
    if (oldVersion.value === '') {
      oldVersion.value = value
    } else {
      newVersion.value = value
      if (oldVersion.value !== newVersion.value) {
        newVersionAvailable.value = true
      }
    }
  }

  // Actions
  const fetchUser = async (
    access: string = 'compile-staff',
    noLoader: boolean = false,
  ) => {
    await appService
      .fetchUser(noLoader)
      .then((res: IUser) => {
        if (
          res &&
          res.is_active &&
          ((access === 'compile-staff' &&
            res.is_staff &&
            res.is_compile_user) ||
            res.entitled_products.find((product) => {
              return product.slug === access
            }) ||
            access === 'all')
        ) {
          // Adding release notes products and the notification status
          // for the same based on the notifications received in api.
          // For custom product notifications, ID of the notification and
          // Slug of the product should be same. FE uses it.
          const releaseNotes = getReleaseNotesProduct()
          releaseNotes.forEach((productItem) => {
            productItem.notif_status = customProductNotifStatus(
              res.notifications,
              productItem,
            )
          })
          res.entitled_products.push(...releaseNotes)
          // Adding faq(guides --> faq) product
          const faq = getFAQProduct()
          faq.notif_status = customProductNotifStatus(res.notifications, faq)
          res.entitled_products.push(faq)
          setUser(res)
          setNotifications(res.notifications)
          setProducts(res.entitled_products)
          setRawProducts(res.entitled_products)
        } else {
          // Allow viz and schema share url to be accessed by user without authentication.
          if (isNoAuthAllowed()) {
            return
          }
          let errorMessage =
            'You do not have access to this, please contact compile_support@mckesson.com'
          if (access === 'compile-staff') {
            errorMessage =
              'You do not have access to this. Please note that this is an alpha version of the tool, and the access to it is limited.'
          }
          // TODO: Validate this
          // createError() doesn't work in non async/await calls and error handled promises.
          // Calls with noLoader true are async/await and it doesn't show loader for better user experience.
          if (noLoader) {
            showError({
              statusCode: 401,
              statusMessage: errorMessage,
              fatal: true,
            })
          } else {
            throw createError({
              statusCode: 401,
              statusMessage: errorMessage,
              fatal: true,
            })
          }
        }
      })
      .finally(() => {
        // Initializing Google Analytics:
        // The user_id is set to hashid (unique identifier) if the user is logged in, otherwise it is set to "public".
        // If the user is not logged in, making an API request to "/users/me/" will result in an error.
        // However, certain pages like the viz-dashboard or schema with share key, which can be accessed without authentication, still require analytics tracking.
        // Therefore, we need to initialize the Google Analytics tracking regardless of the user's authentication status.
        const userId =
          user.value && user.value.hashid ? user.value.hashid : 'public'
        if (!isUserIdSet.value) {
          let shareKey: string | null = ''
          if (window.location.search !== '') {
            const queryStrings = new URLSearchParams(window.location.search)
            shareKey = queryStrings.get('s')
          }
          HelperMethods.initializeGtag(userId, shareKey)
          updateIsUserIdSet(true)
        }
      })
  }

  const fetchVersion = () => {
    appService.versionCheck().then((res: string) => {
      setVersion(res)
    })
  }

  const updateNotificationStatus = async (notificationIDs: string[]) => {
    await appService
      .markNotificationRead(notificationIDs)
      .then((_res: INotification) => {
        updateNotificationStatusAsRead(notificationIDs)
      })
  }

  return {
    user,
    isLoading,
    products,
    notifications,
    breadcrumbs,
    isUnderMaintenance,
    embedPublicUser,
    rawProductsData,
    allowNoAuthUrlList,
    isRenewBannerDismissed,
    isExpiryBannerVisible,
    newVersionAvailable,
    setRawProducts,
    setUserLoaded,
    setEmbedPublicUser,
    setRenewDismissed,
    updateIsUserIdSet,
    updateNotificationStatusAsRead,
    updateProductNotifStatus,
    setUser,
    fetchUser,
    updateNotificationStatus,
    updateIsLoading,
    setBreadcrumbs,
    setIExpiryBannerVisible,
    hasUserInfo,
    isNoAuthAllowed,
    fetchVersion,
  }
})

function getReleaseNotesProduct(): IEntitledProduct[] {
  return [
    {
      slug: 'nov-2024',
      verbose_name: 'McKesson Compile™ Product Updates - November, 2024',
      description:
        'We have improved the accuracy of the data in Open Claims ReadyData™ through the resolution of two minor bugs identified in our processing algorithms.',
      primary_url:
        useRuntimeConfig().public.clientDomain + '/release-notes/nov-2024',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/images/icons/nov2024.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'oct-2024',
      verbose_name: 'McKesson Compile™ Product Updates - October, 2024',
      description:
        'This month’s product update highlights the work our team is doing to improve the quality and usability of McKesson Compile data so that you and your team can get the most out of our partnership. ',
      primary_url:
        useRuntimeConfig().public.clientDomain + '/release-notes/oct-2024',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/images/icons/oct2024.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'aug-2024',
      verbose_name: 'McKesson Compile™ Product Updates - August, 2024',
      description:
        'This month’s product updates highlight our continued focus on creating highly accurate affiliation and hierarchy data within Provider 360 ReadyData™.',
      primary_url:
        useRuntimeConfig().public.clientDomain + '/release-notes/aug-2024',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/images/icons/aug2024.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'jul-2024',
      verbose_name: 'McKesson Compile™ Product Updates - July, 2024',
      description:
        'We have a jam-packed monthly update for you with several exciting product and organizational updates.',
      primary_url:
        useRuntimeConfig().public.clientDomain + '/release-notes/jul-2024',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/images/icons/jul2024.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'jun-2024',
      verbose_name: 'McKesson Compile Product Updates - June, 2024',
      description:
        'This month we’ve added nearly 22,000 child to parent HCO mappings in Provider 360 ReadyData and made several usability and data cleanliness improvements.',
      primary_url:
        useRuntimeConfig().public.clientDomain + '/release-notes/jun-2024',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/images/icons/jun2024.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'may-2024',
      verbose_name: 'McKesson Compile Product Updates - May, 2024',
      description:
        'This month we have added 30,000 HCO mappings to the hierarchy in Provider 360 ReadyData and made additional modifications to the EHR data to ensure patient confidentiality.',
      primary_url:
        useRuntimeConfig().public.clientDomain + '/release-notes/may-2024',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/images/icons/may2024.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'mar-2024',
      verbose_name: 'Compile Product Updates - March, 2024',
      description:
        'Social Determinants of Health (SDOH) data is now available in Compile ReadyData.',
      primary_url:
        useRuntimeConfig().public.clientDomain + '/release-notes/mar-2024',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/images/icons/mar2024.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'jan-2024',
      verbose_name: 'Compile Product Updates - January, 2024',
      description:
        'This month we transitioned the last of our medical claims sources to a weekly refresh cadence, so all medical claims are now updated on a weekly cadence.',
      primary_url:
        useRuntimeConfig().public.clientDomain + '/release-notes/jan-2024',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/images/icons/jan2024.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'dec-2023',
      verbose_name: 'Compile Product Updates - December, 2023',
      description:
        'This month we fixed a bug that had zeroed out a small number of diagnosis code values.',
      primary_url:
        useRuntimeConfig().public.clientDomain + '/release-notes/dec-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/images/icons/dec2023.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'nov-2023',
      verbose_name: 'Compile Product Updates - November, 2023',
      description:
        'This month we updated the meta information for ~400 health systems, which constitutes ~6% of health systems with affiliations in Compile data.',
      primary_url:
        useRuntimeConfig().public.clientDomain + '/release-notes/nov-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/images/icons/nov2023.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'oct-2023',
      verbose_name: 'Compile Product Updates - October, 2023',
      description:
        'This month we updated the health care organization NPI for ~2,000 facilities, which is less than 1% of the facilities in our dataset.',
      primary_url:
        useRuntimeConfig().public.clientDomain + '/release-notes/oct-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/images/icons/oct2023.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'sep-2023',
      verbose_name: 'Compile Product Updates - September, 2023',
      description:
        'This month we enhanced the addresses in our affiliations database by standardizing the addresses to the postal service guidelines, merging duplicate addresses and enforcing consistent address IDs. Overall, 14% of addresses have been updated.',
      primary_url:
        useRuntimeConfig().public.clientDomain + '/release-notes/sep-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/images/icons/sep2023_1.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'aug-2023',
      verbose_name: 'Compile Product Updates - August, 2023',
      description:
        'This month we increased the accuracy of HCP to facility affiliations by 5%, by removing some inaccurate primary HCP to facility affiliations. These affiliations were mostly administrative addresses and are replaced with an affiliation to a site of care.',
      primary_url:
        useRuntimeConfig().public.clientDomain + '/release-notes/aug-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/images/icons/aug2023.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'jul-2023',
      verbose_name: 'Compile Product Updates - July, 2023',
      description:
        'This month we improved matching of Compile IDs to ClinicalTrials.gov.',
      primary_url:
        useRuntimeConfig().public.clientDomain + '/release-notes/jul-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/images/icons/jul2023.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'jun-2023',
      verbose_name: 'Compile Product Updates - June, 2023',
      description:
        'This month’s updates include new features in Compile Console and corrections to a few minor data issues.',
      primary_url:
        useRuntimeConfig().public.clientDomain + '/release-notes/jun-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/images/icons/jun2023.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'may-2023',
      verbose_name: 'Compile Product Updates - May, 2023',
      description:
        "This month's updates include Compile patient ID tokenization upgrade, change in Compile's affiliations coverage, increased NDC to HCPCS relationship coverage and updated data dictionary",
      primary_url:
        useRuntimeConfig().public.clientDomain + '/release-notes/may-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/images/icons/may2023.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'apr-2023',
      verbose_name: 'Compile Product Updates - Apr, 2023',
      description:
        "This month's updates include additional historical claims and notice of an upcoming change to patient IDs",
      primary_url:
        useRuntimeConfig().public.clientDomain + '/release-notes/apr-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/images/icons/apr2023.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'mar-2023',
      verbose_name: 'Compile Product Updates - Mar, 2023',
      description:
        'This month’s updates include new columns for claims tables, improved PubMed matching of HCPs, an upgraded experience for Compile Console',
      primary_url:
        useRuntimeConfig().public.clientDomain + '/release-notes/mar-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/images/icons/mar2023.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'feb-2023',
      verbose_name: 'Compile Product Updates - Feb, 2023',
      description:
        'This month’s updates include expedited claims delivery and upgraded payer channel classifications.',
      primary_url:
        useRuntimeConfig().public.clientDomain + '/release-notes/feb-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/images/icons/feb2023.svg',
      isIconStatic: true,
      notif_status: null,
    },
  ]
}

function getFAQProduct(): IEntitledProduct {
  return {
    slug: 'faq',
    verbose_name: 'FAQ',
    description:
      'Answers to all the questions pertaining to McKesson Compile services and medical data.',
    primary_url: useRuntimeConfig().public.clientDomain + '/guides/faq',
    secondary_url: null,
    secondary_url_text: null,
    category: 'Guides',
    sub_category: '',
    icon: '/images/icons/faq.svg',
    isIconStatic: true,
  }
}

function customProductNotifStatus(
  notifications: INotification[],
  product: IEntitledProduct,
): boolean | null | INotificationStatus {
  if (
    notifications &&
    (product.category === 'Release Notes' || product.category === 'Guides')
  ) {
    const notification = notifications.find(
      (item) => item.id === product.slug && !item.read,
    )
    if (notification) {
      return { category: 'New', notifications: [notification.id], count: 1 }
    }
  } else {
    return product.notif_status || null
  }
  return null
}

function getProducts(rawProductsData: IEntitledProduct[]) {
  const Products: IProducts = {
    Data: [],
    SampleDashboards: [],
    Guides: [],
    ReleaseNotes: [],
    Admin: [],
  }

  const sampleDashboardCategories: ISampleDashboardCategory[] = [
    {
      heading: 'MDM/Customer master',
      slug: `sample-dashboards/mdm-customer-master`,
      data: [],
    },
    {
      heading: 'Commercial analytics',
      slug: `sample-dashboards/commercial-analytics`,
      data: [],
    },
    {
      heading: 'Sales operations',
      slug: `sample-dashboards/sales-ops`,
      data: [],
    },
  ]

  // PDV3 card changes start
  const overrideSchemaProdData = {
    slugs: ['schema_app', 'schema_v3'],
    data: {
      verbose_name: 'Data dictionary',
      description:
        'Tables and fields for all the data in McKesson Compile, including medical and pharmacy claims, provider affiliations and provider metadata',
    },
  }
  const schemaApps = overrideSchemaProdData.slugs
  const bothSchema =
    rawProductsData.filter((product) => schemaApps.includes(product.slug))
      .length === 2
  //  // PDV3 card changes end

  rawProductsData.forEach((product) => {
    if (product.category === 'Sample Dashboards') {
      sampleDashboardCategories.forEach((s) => {
        if (s.heading === product.sub_category) {
          const data: IProduct = cleanedData(product)
          s.data.push(data)
        }
      })
    } else {
      // PDV3 card changes
      if (!bothSchema && schemaApps.includes(product.slug)) {
        product = {
          ...product,
          verbose_name: overrideSchemaProdData.data.verbose_name,
          description: overrideSchemaProdData.data.description,
        }
      }

      const data: IProduct = cleanedData(product)
      // Removing the space in Products key name. This is to handle the category name with space(Release Notes).
      // It is not a good practice to have the space in object keys.
      if (product.category.replace(' ', '') in Products) {
        ;(Products[product.category.replace(' ', '')] as IProduct[]).push(data)
      }
    }
  })

  sampleDashboardCategories.forEach((c) => {
    if (c.data.length > 0) {
      Products.SampleDashboards.push(c)
    }
  })

  return Products
}

function cleanedData(product: IEntitledProduct) {
  const data: IProduct = {
    title: product.verbose_name,
    subTitle: product.description,
    svgPath: product.icon,
    url: product.primary_url,
    notif_status: product.notif_status ? product.notif_status : null,
    category: product.category,
    slug: product.slug,
    secondaryUrl: '',
    secondaryUrlText: '',
  }
  if (product.secondary_url !== null) {
    data.secondaryUrl = product.secondary_url
    data.secondaryUrlText = product.secondary_url_text
  }
  if (product.icon === null) {
    data.svgPath = '/Group 1040.svg'
  } else if (product.icon.startsWith('/') && !product.isIconStatic) {
    data.svgPath = process.env.rootURL + product.icon
  }
  return data
}
